<template>
    <Component
        :is="require(`@/assets/svgs/${name}.svg`)"
        v-bind="$attrs"
        class="base-icon"
        @v-on="$listeners"
        :style="{
            height: `${height}`,
            width: `${width}`,
            minHeight: `${height}`,
            minWidth: `${width}`,
        }"
    />
</template>

<script>
export default {
    name: "BaseIcon",
    // Transparent wrapper component
    // https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
    inheritAttrs: true,
    props: {
        name: {
            type: String,
            required: true,
        },
        height: {
            type: String,
        },
        width: {
            type: String,
        },
    },
};
</script>

<style lang="scss" scoped>
.base-icon {
    min-height: 25px;
    min-width: 25px;
}
</style>




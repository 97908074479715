































































































































































































































































































import Vue from "vue";

export default Vue.extend({
    name: "app",
    data() {
        return {
            name: "",
            email: "",
            agency: "",
            department: "",
            information: "",
            vueTel: {
                phone: "",

                props: {
                    preferredCountries: ["US", "GB"],
                    placeholder: "Enter your phone",
                    mode: "international",
                    inputOptions: {
                        showDialCode: true,
                    },
                    disabledFormatting: false,
                    wrapperClasses: "country-phone-input",
                },
            },
            vuePhone: {
                phone: "",
                props: {
                    clearable: true,
                    fetchCountry: true,
                    preferredCountries: ["US", "GB"],
                    noExample: false,
                    translations: {
                        countrySelectorLabel: "Country code",
                        countrySelectorError: "Error",
                        phoneNumberLabel: "Enter your phone",
                        example: "Example:",
                    },
                },
            },
        };
    },
});

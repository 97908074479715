













import Vue from "vue";

export default Vue.extend({
    props: {
        icon: {
            type: String,
        },
        emptyText: {
            type: String,
        },
    },
});

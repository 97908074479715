
























































import Vue from "vue";
import { HOME, SETTINGS } from "../app/routes/endpoints";

enum IItemStatus {
    ACTIVE,
    INACTIVE,
}

export default Vue.extend({
    data() {
        return {
            IItemStatus,
            HOME,
            SETTINGS,
            items: [
                // {
                //     name: "Reporting Tool",
                //     route: HOME.REPORTS.NAME,
                //     status: IItemStatus.ACTIVE,
                //     logo: "ba-reporting-tool-logo",
                // },
                // {
                //     name: "Governance Portal",
                //     route: HOME.REPORTS.NAME,
                //     status: IItemStatus.INACTIVE,
                //     logo: "ba-governance-portal-logo",
                // },
                // {
                //     name: "Corporate Universe",
                //     route: HOME.REPORTS.NAME,
                //     status: IItemStatus.INACTIVE,
                //     logo: "ba-corporate-universe-logo",
                // },
                // {
                //     name: "Investor Relations",
                //     route: HOME.REPORTS.NAME,
                //     status: IItemStatus.INACTIVE,
                //     logo: "ba-investor-relations-logo",
                // },
                // {
                //     name: "Enterprise Sustainability",
                //     route: HOME.REPORTS.NAME,
                //     status: IItemStatus.INACTIVE,
                //     logo: "ba-enterprise-sustainability-logo",
                // },
                // {
                //     name: "Contract Domain",
                //     route: HOME.REPORTS.NAME,
                //     status: IItemStatus.INACTIVE,
                //     logo: "ba-contract-domain-logo",
                // },
                // {
                //     name: "Dispute Portfolio",
                //     route: HOME.REPORTS.NAME,
                //     status: IItemStatus.INACTIVE,
                //     logo: "ba-dispute-portfolio-logo",
                // },
            ],
        };
    },
    methods: {
        handleItemClick(item: any) {
            if (item.status === IItemStatus.ACTIVE) {
                this.$router.push({ name: item.route });
                this.$emit("item-clicked");
            }
        },
    },
});

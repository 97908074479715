<template>
    <div class="base-modal-container relative">
        <b-modal
            :id="`modal-${id}`"
            :ref="`modal-${id}`"
            :size="size"
            hide-footer
            centered
            v-bind="$attrs"
            hide-header
            content-class="border-0 pb-4"
        >
            <slot name="close-content">
                <div class="d-flex justify-content-end">
                    <div
                        class="text-right h2 pointer"
                        @click="$bvModal.hide(`modal-${id}`)"
                    >
                        &times;
                    </div>
                </div>
            </slot>
            <div class="px-4">
                <div class="d-flex">
                    <h5 class="mb-0 title font-weight-bold text-black">
                        {{ title }}
                    </h5>
                </div>
                <div
                    class="font-weight-light text-sm-2 text-gray-200 mt-2 text-left"
                    v-html="description"
                ></div>
            </div>

            <div :class="`pb-4 pt-4 px-4 fixed ${customContentClass}`">
                <slot />
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    inheritAttrs: true,
    props: {
        "id": {
            type: [Number, String],
            required: true,
        },
        "size": {
            type: String,
            default: "lg",
        },
        "custom-content-class": String,
        "title": String,
        "description": String,
        "closeBtn": Boolean,
    },
};
</script>

<style lang="scss">
.modal-content {
    border-radius: $border-radius !important;
    -webkit-border-radius: $border-radius !important;
    -moz-border-radius: $border-radius !important;
    overflow: hidden;

    .title {
        margin-top: -20px;
    }
}
</style>

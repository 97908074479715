


















































































































import Vue from "vue";

interface Ivalue {
    image: string;
    fullName: string;
    email: string;
    roles: string;
}

enum PositionType {
    executive = "executive",
    director = "director",
    employee = "employee",
}

export default Vue.extend({
    inheritAttrs: true,
    props: {
        label: {
            default: "Label",
        },
        type: {
            default: "multiple",
        },
        values: {
            default: () => [],
        },
        required: {
            type: Boolean,
            default: false,
        },
        defaultValue: {
            default: () => [],
        },
    },
    data(): { input: string; valuesArray: Array<Ivalue> } {
        return {
            input: "",
            valuesArray: [],
        };
    },
    methods: {
        isFound(value: Ivalue) {
            const index = this.valuesArray.findIndex(
                (val: Ivalue) => val.email === value.email,
            );
            if (index >= 0) {
                return true;
            }
            return false;
        },
        onFocus() {
            const ulTag = this.$refs.ul as any;
            ulTag.style.display = "block";
        },
        closeSelect() {
            const ulTag = this.$refs.ul as any;
            setTimeout(() => {
                ulTag.style.display = "none";
            }, 150);
        },
        onFocusOut() {
            this.closeSelect();
            this.clearInput();
        },
        clearInput() {
            this.input = "";
            setTimeout(() => {
                this.resetLiInputs();
            }, 150);
        },
        resetLiInputs() {
            const liTags = this.$refs.li as any;
            for (let i = 0; i < liTags.length; i += 1) {
                liTags[i].style.display = "";
            }
        },
        addSingleValue(value: Ivalue) {
            this.valuesArray = [value];
            this.$emit("input", this.valuesArray);
            this.clearInput();
        },
        addMultipleValue(value: Ivalue) {
            const index = this.valuesArray.findIndex(
                (val: Ivalue) => val.email === value.email,
            );
            if (index >= 0) {
                this.valuesArray.splice(index, 1);
            } else {
                this.valuesArray.push(value);
            }
            this.$emit("input", this.valuesArray);
            this.clearInput();
        },
        deleteValue(value: Ivalue) {
            const index = this.valuesArray.findIndex(
                (val: Ivalue) => val.email === value.email,
            );
            this.valuesArray.splice(index, 1);
        },
        search() {
            const liTags = this.$refs.li as any;
            for (let i = 0; i < liTags.length; i += 1) {
                const a = liTags[i].getElementsByTagName("a")[0];
                const txtValue = a.textContent || a.innerText;
                const filter = this.input.toUpperCase();
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    liTags[i].style.display = "";
                } else {
                    liTags[i].style.display = "none";
                }
            }
        },
        checkType(value: string) {
            switch (value.toLowerCase()) {
                case PositionType.executive:
                case PositionType.employee:
                    return "blue";
                case PositionType.director:
                    return "yellow";
                default:
                    return "blue";
            }
        },
    },
    watch: {
        defaultValue() {
            this.valuesArray = this.defaultValue;
        },
    },
    mounted() {
        this.valuesArray = this.defaultValue;
    },
});

import Vue from "vue";
import VueRouter, { RouteConfig, RouteRecord } from "vue-router";
import { HOME, AUTH, SETTINGS } from "@/app/routes/endpoints";

import isUserLoggedIn from "@/utils/auth";

Vue.use(VueRouter);

const routes = (): RouteConfig[] => [
    {
        path: AUTH.LANDING.PATH,
        name: AUTH.LANDING.NAME,
        component: () =>
            import(
                /* webpackChunkName: "landing" */ "../app/pages/auth/Landing.vue"
            ),
    },
    {
        path: AUTH.LAYOUT,
        component: () =>
            import(
                /* webpackChunkName: "auth" */ "../app/layouts/AuthLayout.vue"
            ),
        children: [
            {
                path: AUTH.LOGIN.PATH,
                name: AUTH.LOGIN.NAME,
                component: () =>
                    import(
                        /* webpackChunkName: "auth" */ "../app/pages/auth/Login.vue"
                    ),
            },
            {
                path: AUTH.SIGN_UP.PATH,
                name: AUTH.SIGN_UP.NAME,
                component: () =>
                    import(
                        /* webpackChunkName: "auth" */ "../app/pages/auth/SignUp.vue"
                    ),
            },
            {
                path: AUTH.FORGOT_PASSWORD.PATH,
                name: AUTH.FORGOT_PASSWORD.NAME,
                component: () =>
                    import(
                        /* webpackChunkName: "auth" */ "../app/pages/auth/ForgotPassword.vue"
                    ),
            },
            {
                path: AUTH.VERIFY_EMAIL.PATH,
                name: AUTH.VERIFY_EMAIL.NAME,
                component: () =>
                    import(
                        /* webpackChunkName: "auth" */ "../app/pages/auth/VerifyEmail.vue"
                    ),
            },
        ],
    },
    {
        path: HOME.LAYOUT,
        component: () =>
            import(
                /* webpackChunkName: "home" */ "../app/layouts/DashboardLayout.vue"
            ),
        children: [
            {
                path: HOME.INDEX.PATH,
                name: HOME.INDEX.NAME,
                component: () =>
                    import(
                        /* webpackChunkName: "home" */ "../app/pages/Index.vue"
                    ),
                meta: { requiresAuth: true },
            },
            {
                path: HOME.REPORTS.PATH,
                name: HOME.REPORTS.NAME,
                component: () =>
                    import(
                        /* webpackChunkName: "home" */ "../app/pages/report/Index.vue"
                    ),
                meta: { requiresAuth: true },
            },
            {
                path: HOME.VIEW_REPORT.PATH,
                name: HOME.VIEW_REPORT.NAME,
                component: () =>
                    import(
                        /* webpackChunkName: "home" */ "../app/pages/report/ViewReport.vue"
                    ),
                meta: { requiresAuth: true },
            },
            {
                path: HOME.VIEW_REPORT_HISTORY.PATH,
                name: HOME.VIEW_REPORT_HISTORY.NAME,
                component: () =>
                    import(
                        /* webpackChunkName: "home" */ "../app/pages/report/ViewReportHistory.vue"
                    ),
                meta: { requiresAuth: true },
            },
        ],
    },
    {
        path: SETTINGS.LAYOUT,
        component: () =>
            import(
                /* webpackChunkName: "home" */ "../app/layouts/DashboardLayout.vue"
            ),
        children: [
            {
                path: SETTINGS.INDEX.PATH,
                name: SETTINGS.INDEX.NAME,
                component: () =>
                    import(
                        /* webpackChunkName: "settings" */ "../app/pages/settings/Index.vue"
                    ),
            },
            {
                path: SETTINGS.PROFILE.PATH,
                name: SETTINGS.PROFILE.NAME,
                component: () =>
                    import(
                        /* webpackChunkName: "settings" */ "../app/pages/settings/Profile.vue"
                    ),
            },
            {
                path: SETTINGS.BUSINESS.PATH,
                name: SETTINGS.BUSINESS.NAME,
                component: () =>
                    import(
                        /* webpackChunkName: "settings" */ "../app/pages/settings/Business.vue"
                    ),
            },
            {
                path: SETTINGS.USER_ACCESS.PATH,
                name: SETTINGS.USER_ACCESS.NAME,
                component: () =>
                    import(
                        /* webpackChunkName: "settings" */ "../app/pages/settings/UserAccess.vue"
                    ),
            },
        ],
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: routes(),
    scrollBehavior(to, from, savedPosition): any {
        console.log(savedPosition);
        if (savedPosition) {
            return savedPosition;
        }
        return { top: 0 };
    },
});

let activedRoutes: RouteRecord[] = [];

// Sets breadcrumbs list
router.beforeEach((to, from, next) => {
    activedRoutes = [];
    to.matched.forEach((record) => {
        activedRoutes.push(record);
    });

    if (activedRoutes.some((route) => route.meta.requiresAuth)) {
        if (activedRoutes.some((record) => record.meta.requiresAuth)) {
            if (!isUserLoggedIn()) {
                next({
                    path: "/login",
                    query: { redirect: to.fullPath },
                });
            } else {
                next();
            }
        } else {
            next();
        }
    }

    next();
});

export default { router, activedRoutes };




















import Vue from "vue";
import {mapActions} from "vuex";

export default Vue.extend({
  inheritAttrs: true,
  name: "BaFileUpload",
  props: {
	buttonText: {
	  type: String,
	  required: false,
	  default: 'Upload'
	},
	buttonIcon: {
	  type: String,
	  required: false,
	  default: 'ba-upload'
	},
  },
  data(){
	return {
	  loading: false
	}
  },
  methods: {
	...mapActions({
	  uploadFile: 'file/uploadFile',
	  fetchFile: 'file/fetchFile',
	  removeFile: 'file/removeFile',
	}),
	triggerUpload() {
	  const fileInput = this.$refs['file-upload-input'] as HTMLInputElement
	  if (fileInput) {
		fileInput.click()
	  }
	},
	async processFileUpload(file: any) {
	  this.loading = true
	  const formData = new FormData()
	  formData.append('file', file)
	  try {
		const response = await this.uploadFile(formData)
		this.$bvToast.toast('', {
		  title: "File Upload successful!",
		  variant: "success",
		  solid: true,
		});
		this.$emit("completed", response)
	  } catch (e) {
		this.$bvToast.toast('', {
		  title: "Unable to upload file!",
		  variant: "error",
		  solid: true,
		});
	  }
	  this.loading = false
	}
  }
});









































































import Vue from "vue";
import { formatFileSize } from "@/utils/helpers";
import UploadDocumentModal from "@/app/components/modals/UploadDocumentModal.vue";
import { mapActions } from "vuex";

export default Vue.extend({
    props: {
        label: {
            type: String,
        },
        description: {
            type: String,
        },
        isRemovable: {
            type: Boolean,
            default: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        savedFiles: {
            type: Array,
            default: () => [],
            required: false,
        },
        identity: {
            type: Object,
            default: () => ({
                fileId: "",
                versionNumber: 0,
                fileName: "",
                fileSize: "",
                fileCreationTime: "",
                fileLastModificationTime: "",
            }),
        },
    },
    components: {
        UploadDocumentModal,
    },
    mounted() {
        if (this.savedFiles.length > 0) {
            this.files = [...this.savedFiles];
        }
    },
    data(): {
        file: File | null | string;
        files: any;
        showModal: boolean;
    } {
        return {
            file: null,
            files: [],
            showModal: false,
        };
    },
    methods: {
        ...mapActions({
            deleteFile: "file/removeFile",
            fetchFile: "file/fetchFile",
        }),
        formatFileSize,
        toggleModal() {
            this.showModal = true;
            this.$bvModal.show(`modal-${this.label.replaceAll(" ", "")}`);
        },
        async processFileUpload(file: any) {
            this.files = [...this.files, file];
            this.showModal = false;
            await this.$bvModal.hide(`modal-${this.label.replaceAll(" ", "")}`);
            this.$emit("input", this.files);
        },
        async removeFile(index: number): Promise<void> {
            this.file = "";
            const tempFile = this.files[index];
            const { docUploadId } = tempFile;
            await this.processFileDeletion(docUploadId);
            this.files.splice(index, 1);
            (this.$refs.filePicker as HTMLInputElement).value = "";
            this.$emit("resetIdentity");
            this.$emit("input", this.files);
        },
        async processFileDeletion(docUploadId: string): Promise<void> {
            try {
                await this.deleteFile(docUploadId);
            } catch (e) {
                this.$bvToast.toast(
                    "Something went wrong - this will be corrected",
                    {
                        title: "Error!",
                        variant: "danger",
                        solid: true,
                    },
                );
            }
        },
        async viewFile(fileName: string): Promise<void> {
            try {
                const { presignedUrl } = await this.fetchFile(fileName);
                window.open(presignedUrl, "_blank");
            } catch (e) {
                this.$bvToast.toast("", {
                    title: "Unable to fetch file!",
                    variant: "error",
                    solid: true,
                });
            }
        },
    },
    watch: {
        // files() {
        //     this.$emit("input", this.files);
        // },
        savedFiles(val) {
            if (val.length > 0) {
                this.files = [...val];
            }
        },
    },
});

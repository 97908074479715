













import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
    computed: {
        ...mapGetters({
            companyData: "company/getProfile",
            files: "file/getFiles",
        }),
        companyLogoId(): string {
            if (this.companyData && this.companyData.companyProfile) {
                const { logoImgUrl } = this.companyData.companyProfile;
                return logoImgUrl;
            }
            return "";
        },
        companyLogoUrl(): string {
            const imageObj = this.files[this.companyLogoId];
            return imageObj && imageObj.presignedUrl;
        },
    },
    watch: {
        async companyData(val) {
            if (val.companyProfile) {
                await this.getFileUrl(this.companyLogoId);
            }
        },
    },
    async mounted() {
        if (this.companyLogoId) {
            await this.getFileUrl(this.companyLogoId);
        }
    },
    methods: {
        ...mapActions({
            fetchFile: "file/fetchFile",
            fetchCompanyProfile: "company/fetchProfile",
        }),
        async getFileUrl(filename: string): Promise<void> {
            try {
                await this.fetchFile(filename);
            } catch (e) {
                this.$bvToast.toast("Error", {
                    title: "Unable to fetch file!",
                    variant: "error",
                    solid: true,
                });
            }
        },
    },
});























































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { RouteMeta } from "vue-router";
import { HOME, SETTINGS, AUTH } from "@/app/routes/endpoints";

export default Vue.extend({
    data() {
        return {
            role: "Administrator",
            avatarOptions: [
                {
                    label: "My Profile",
                    action: () => {
                        this.$router.push({
                            name: SETTINGS.PROFILE.NAME,
                        });
                    },
                },

                {
                    label: "Change Password",
                    action: () => {
                        this.$router.push({
                            name: SETTINGS.PROFILE.NAME,
                        });
                    },
                },
                {
                    label: "Settings",
                    action: () => {
                        this.$router.push({
                            name: SETTINGS.INDEX.NAME,
                        });
                    },
                },
                {
                    label: "Log out",
                    action: () => {
                        localStorage.clear();
                        this.$router.push({
                            name: AUTH.LOGIN.NAME,
                        });
                    },
                },
            ],
        };
    },
    async mounted() {
        if (this.profileImageId) {
            await this.getFileUrl(this.profileImageId);
        }
    },
    computed: {
        ...mapGetters({
            getUserData: "auth/getUserData",
            files: "file/getFiles",
        }),
        breadcrumbs(): [] {
            const routeMeta: RouteMeta | undefined = this.$route.meta;

            if (typeof routeMeta?.breadcrumbs === "function") {
                return routeMeta.breadcrumbs.call(this, this.$route);
            }
            return routeMeta?.breadcrumbs;
        },
        profileImageId(): string {
            const { profileImgId } = this.getUserData;
            return profileImgId;
        },
        profileImageUrl(): string {
            const imageObj = this.files[this.profileImageId];
            return imageObj && imageObj.presignedUrl;
        },
        imageOrAvatar(): any {
            return this.profileImageUrl
                ? { src: this.profileImageUrl }
                : { icon: "people-fill" };
        },
    },
    methods: {
        ...mapActions({
            fetchFile: "file/fetchFile",
        }),
        async getFileUrl(filename: string): Promise<void> {
            try {
                await this.fetchFile(filename);
            } catch (e) {
                this.$bvToast.toast("", {
                    title: "Unable to fetch file!",
                    variant: "error",
                    solid: true,
                });
            }
        },
        goHome() {
            this.$router.push({ name: HOME.REPORTS.NAME });
        },
        handleMenuClick(e: any) {
            this.$emit("click-menu", e);
        },
    },
});





































































import Vue from "vue";

export default Vue.extend({
    props: {
        title: {
            type: String,
        },
        tabs: {
            type: Array,
            required: true,
        },
        currentTab: {
            type: [Number, String, Object],
            default: 0,
        },
    },
    data() {
        return {
            activeTab: this.currentTab,
        };
    },
    watch: {
        activeTab(currTab, prev) {
            if (currTab !== prev) {
                this.$emit("changeTab", currTab);
            }
        },
        currentTab(curr, prev) {
            if (curr !== prev) {
                this.activeTab = curr;
            }
        },
    },
    mounted() {
        console.log(this.tabs, this.currentTab);
    },
});

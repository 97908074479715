




































































































































import Vue from "vue";
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import TextAlign from "@tiptap/extension-text-align";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";

const CustomTableCell = TableCell.extend({
    addAttributes() {
        return {
            // extend the existing attributes …
            ...this.parent?.(),

            // and add a new one …
            backgroundColor: {
                default: null,
                parseHTML: (element: any) =>
                    element.getAttribute("data-background-color"),
                renderHTML: (attributes: any) => ({
                    "data-background-color": attributes.backgroundColor,
                    "style": `background-color: ${attributes.backgroundColor}`,
                }),
            },
        };
    },
});

export default Vue.extend({
    inheritAttrs: true,
    props: {
        label: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
        },
        value: {
            type: String,
            default: "<p></p>",
        },
        required: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        EditorContent,
    },
    data() {
        // I brought this here because the way it was before it was instantiating 2 editor instances and was causing some issues
        // eslint-disable-next-line
        const self = this;
        const newEditor = new Editor({
            content: this.value,
            extensions: [
                StarterKit,

                TextAlign.configure({
                    types: ["heading", "paragraph"],
                }),
                Table.configure({
                    resizable: true,
                }),
                TableRow,
                TableHeader,
                CustomTableCell,
            ],
            onBlur({ event }) {
                self.$emit("blur", event);
            },
            // The on update listener in handler doesn't seem to work for some reason. This is a temporary fix
            onUpdate({ editor }) {
                self.$emit("input", editor.getHTML());
            },
        });

        return {
            editor: newEditor,
            textStyles: ["Paragraph", "Title", "Heading 1", "Heading 2"],
        };
    },
    methods: {
        destroyEditor() {
            this.editor.destroy();
        },
    },

    watch: {
        editor: {
            immediate: true,
            handler(editor) {
                if (!editor || !editor.element) return;

                this.editor.commands.setContent(this.value);
                this.editor.on("update", ({ editor: ed }) => {
                    this.$emit("input", ed.getHTML());
                    console.log("emittinggggggg", ed.getHTML());
                });

                this.$nextTick(() => {
                    this.$el.appendChild(editor.element.firstChild);
                    editor.setParentComponent(this);
                });
            },
        },
        value: {
            handler(value) {
                this.editor.commands.setContent(value);
            },
        },
    },

    render(createElement) {
        return createElement("div");
    },

    beforeDestroy() {
        this.destroyEditor();
    },
});



































import Vue from "vue";

export default Vue.extend({
    props: {
        item: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            isHovered: false,
        };
    },
    computed: {
        circlePosition(): string {
            return this.index % 2 === 0 ? "top-right" : "top-left";
        },
        imagePosition(): string {
            return this.index % 2 === 0 ? "bottom-right" : "bottom-left";
        },
    },
});






















import Vue from "vue";

export default Vue.extend({
    props: {
        items: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
        },
        modalId: {
            type: String,
            required: true,
        },
        clickItem: {
            type: Function,
        },
    },
});






























import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            preview: null,
            image: null,
        };
    },
    methods: {
        previewImage(event: any) {
            const input = event.target;
            if (input.files) {
                const reader = new FileReader();
                reader.onload = (e: any) => {
                    this.preview = e.target.result;
                };
                const [file] = input.files;

                this.image = file;
                reader.readAsDataURL(input.files[0]);
            }
        },
        openImagePicker() {
            console.log("Rahhh");
            (this.$refs.imagePicker as HTMLDivElement).click();
        },
    },
});
